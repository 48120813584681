import { NgClass } from '@angular/common';
import { NgModule } from '@angular/core';
import { ColorStatusIndicatorComponent } from './color-status-indicator.component';

@NgModule({
    declarations: [ColorStatusIndicatorComponent],
    imports: [NgClass],
    exports: [ColorStatusIndicatorComponent]
})
export class ColorStatusIndicatorModule {}
