import { Component, Input } from '@angular/core';

@Component({
    selector: 'color-status-indicator',
    template: `
        <div
            class="status-indicator-square"
            [ngClass]="statusClass"
            [style.background-color]="statusColor"
            [style.top.px]="verticalOffset"
            [style.width.px]="size"
            [style.height.px]="size"
            [style.border-radius.px]="borderRadius"
        ></div>
    `,
    styles: [
        `
            .status-indicator-square {
                position: relative;
                display: inline-block;
                margin-right: 3px;
            }
        `
    ],
    standalone: false
})
export class ColorStatusIndicatorComponent {
    @Input() public statusColor: string = '';
    @Input() public statusClass: string = '';
    @Input() public verticalOffset: number = -1;
    @Input() public size: number = 8;
    @Input() public borderRadius: number = 0;
}
